import "./src/styles/index.scss";
import "vanilla-cookieconsent/dist/cookieconsent.css";

import { GatsbyBrowser } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

import { ConfigCookieConsent } from "./src/components/CookieConsent/CookieConsent";
import { CartContextProvider } from "./src/contexts/shop/cartContext";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <CartContextProvider>{element}</CartContextProvider>;
};

export const wrapPageElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  function setCssVh() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`,
    );
  }
  useEffect(() => {
    setCssVh();
    window.addEventListener("resize", setCssVh);
    window.addEventListener("orientationchange", setCssVh);
    window.addEventListener("pageshow", setCssVh);
    CookieConsent.run(ConfigCookieConsent);
    return () => {
      window.removeEventListener("resize", setCssVh);
      window.removeEventListener("orientationchange", setCssVh);
      window.removeEventListener("pageshow", setCssVh);
    };
  }, []);

  return element;
};
