exports.components = {
  "component---src-contexts-pages-templates-about-us-page-about-us-tsx": () => import("./../../../src/contexts/Pages/templates/aboutUs/PageAboutUs.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-about-us-page-about-us-tsx" */),
  "component---src-contexts-pages-templates-blog-page-blog-tsx": () => import("./../../../src/contexts/Pages/templates/blog/PageBlog.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-blog-page-blog-tsx" */),
  "component---src-contexts-pages-templates-formation-page-formation-tsx": () => import("./../../../src/contexts/Pages/templates/formation/PageFormation.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-formation-page-formation-tsx" */),
  "component---src-contexts-pages-templates-home-page-home-tsx": () => import("./../../../src/contexts/Pages/templates/home/PageHome.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-home-page-home-tsx" */),
  "component---src-contexts-pages-templates-legal-page-legal-tsx": () => import("./../../../src/contexts/Pages/templates/legal/PageLegal.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-legal-page-legal-tsx" */),
  "component---src-contexts-pages-templates-post-page-post-tsx": () => import("./../../../src/contexts/Pages/templates/post/PagePost.tsx" /* webpackChunkName: "component---src-contexts-pages-templates-post-page-post-tsx" */),
  "component---src-contexts-services-templates-service-page-service-tsx": () => import("./../../../src/contexts/Services/templates/service/PageService.tsx" /* webpackChunkName: "component---src-contexts-services-templates-service-page-service-tsx" */),
  "component---src-contexts-services-templates-subservice-page-subservice-tsx": () => import("./../../../src/contexts/Services/templates/subservice/PageSubservice.tsx" /* webpackChunkName: "component---src-contexts-services-templates-subservice-page-subservice-tsx" */),
  "component---src-contexts-shop-templates-category-page-tsx": () => import("./../../../src/contexts/shop/templates/category-page.tsx" /* webpackChunkName: "component---src-contexts-shop-templates-category-page-tsx" */),
  "component---src-contexts-shop-templates-product-page-tsx": () => import("./../../../src/contexts/shop/templates/product-page.tsx" /* webpackChunkName: "component---src-contexts-shop-templates-product-page-tsx" */),
  "component---src-contexts-shop-templates-subcategory-page-tsx": () => import("./../../../src/contexts/shop/templates/subcategory-page.tsx" /* webpackChunkName: "component---src-contexts-shop-templates-subcategory-page-tsx" */),
  "component---src-pages-tienda-drones-tsx": () => import("./../../../src/pages/tienda/drones.tsx" /* webpackChunkName: "component---src-pages-tienda-drones-tsx" */),
  "component---src-pages-tienda-gracias-tsx": () => import("./../../../src/pages/tienda/gracias.tsx" /* webpackChunkName: "component---src-pages-tienda-gracias-tsx" */),
  "component---src-pages-tienda-tsx": () => import("./../../../src/pages/tienda.tsx" /* webpackChunkName: "component---src-pages-tienda-tsx" */),
  "slice---src-slices-footer-footer-legals-tsx": () => import("./../../../src/slices/Footer/footer-legals.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-legals-tsx" */),
  "slice---src-slices-footer-footer-slice-tsx": () => import("./../../../src/slices/Footer/footer-slice.tsx" /* webpackChunkName: "slice---src-slices-footer-footer-slice-tsx" */),
  "slice---src-slices-main-menu-main-menu-services-tsx": () => import("./../../../src/slices/MainMenu/MainMenuServices.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-services-tsx" */),
  "slice---src-slices-main-menu-main-menu-tsx": () => import("./../../../src/slices/MainMenu/MainMenu.tsx" /* webpackChunkName: "slice---src-slices-main-menu-main-menu-tsx" */)
}

