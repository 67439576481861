import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { Cart } from "../../../shop/store/domain/Cart";

type CartSession = {
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
};
const inicialCart: Cart = {
  id: 0,
  associations: {
    cart_rows: [],
  },
  date_add: "",
  date_upd: "",
  id_currency: 1,
  id_lang: 1,
};

const useCart = () => {
  const [cart, setCart] = useState<Cart>(
    JSON.parse(window.localStorage.getItem("cart")) || inicialCart,
  );
  useEffect(() => {
    const cartSession: string | null =
      window.localStorage.getItem("cart") || null;
    if (cartSession) {
      const cartSessionJson = JSON.parse(cartSession);
      if (cartSessionJson.id !== 0) {
        setCart(JSON.parse(cartSession));
      }
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return { cart, setCart };
};

const CartContext = createContext<CartSession>({
  cart: inicialCart,
  setCart: () => {},
});

const CartContextProvider = ({ children }: PropsWithChildren) => {
  const { cart, setCart } = useCart();
  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  const { cart, setCart } = useContext(CartContext);
  return { cart, setCart };
};

export { CartContextProvider, useCartContext };
