const enum Language {
  ES = "es_es",
  EN = "en",
}
const DEFAULT_LANGUAGE: Language = Language.ES;

export { DEFAULT_LANGUAGE, Language };

export const Languages: { title: string; value: Language }[] = [
  { title: "Español", value: Language.ES },
  { title: "Inglés", value: Language.EN },
];
